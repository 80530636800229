.modal-container {
  display: flex;
  flex-direction: column;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index:  901;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  .modal-content {
    position: absolute;
    width: 600px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    background-color: #fff;
    left: 50%;
    margin-left: -300px;
    box-shadow: 0px 0px 30px 0px #000000;
    border-radius: 20px;
    .close-modal {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 10px;
        cursor: pointer;
    }
    .adress {
      margin-left: 0;
      margin-top: 5px;
    }
    .border-eco {
      border-top: solid 1px #0092c5;
      padding-top: 20px;
    }
    .modal-list {
      /* border-top: solid 1px #e16e5e; */
      padding-left: 0;
      margin-left: 0;
      span {
        display: inline-block;
        font-family: roboto-black, Helvetica, Arial, sans-serif;
        font-size: 12px;
        color: #58585a;
        margin-right: 10PX;
      }
      .modal-link {
        display: inline-block;
        font-family: roboto, Helvetica, Arial, sans-serif;
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 10px;
        color: #fff;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }
}