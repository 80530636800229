$primary-color: #0092c5;
$primary-hover: #0282b1;
$secondary-color: #9C867A;
$text-dark: #002B3F;
$text-color: #58585a;
$red: #FF7D66;
$light-grey: rgb(238, 237, 237);
$shadow: 0px -1px 17px -6px rgba(0,0,0,0.3);
$white: #fff;

@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center; 
}

@mixin btn {
  display: flex;
  align-items: center;
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1;
  margin: 10px;
  padding: 8px 15px;
  font-weight: normal;
  border-radius: 35px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

@mixin input {
  width: 280px;
  height: 32px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid rgba(0,136, 191, 0.2);
  box-shadow: 0px 10px 58px 0px rgba(18, 51, 89, 0.06);
  color: #0092c5;
  background-color: #fff;
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  border-radius: 35px;
  padding-left: 15px;
}

