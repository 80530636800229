
.sidebar {
  background-color: #fff;
  display: block;
  position: absolute;
  right: 0;
  width: 350px;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 50;
  background-image: url(../assets/images/separateur-list.png);
  background-position: left center;
  background-size: auto 80%;
  background-repeat: no-repeat;
  .underline {
    width: 170px;
    height: 3px;
    background: $primary-color;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  .accueil {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .list-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    img {
      margin-top: 30px;
      align-self: center;
    }
    h2 {
      margin-left: 45px;
      margin-right: 45px;
      margin-bottom: 10px;
      margin-top: 20px;
      font-size: 18px;
    }
    h3 {
      border-bottom: solid 3px $primary-color;
      margin-left: 45px;
      margin-right: 45px;
      padding-bottom: 10px;
      margin-bottom: 20px;
      margin-top: 0px;
      color: $text-color;
      font-size: 14px;
      font-family: roboto-black;
      span {
        font-family: roboto;
        font-weight: 300;
        &.date {
          opacity: 0.6;
        }
      }
    }
    .list {
      overflow-x: hidden;
      overflow-y: auto;
      padding-left: 20px;
      padding-right: 20px;
      .list-item {
        color: $text-color;
        font-size: 12px;
        h4 {
          font-family: roboto;
          margin: 10px 0 0 0;
          cursor: pointer;
          &:hover {
            color: $primary-color;
          }
        }
        span {
          font-family: roboto;
        }
      }
    }
  }
}
