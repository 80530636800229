.nav-links-container {
  position: absolute;
  left: 0;
  right: 350px;
  z-index: 700;
  display: flex;
  pointer-events: none;
  justify-content: center;
  align-items: flex-start;
  .link-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .link-circle {
      @include flex-centered;
      height: 60px;
      width: 60px;
      border-radius: 40px;
      transition: all .2s ease-in-out;
      position: relative;
      pointer-events: all;
      cursor: pointer;
      img {
        width: 30px;
      }
    }
    .link-circle-market {
      @include flex-centered;
      height: 60px;
      width: 60px;
      border-radius: 40px;
      pointer-events: all;
      transition: all .2s ease-in-out;
      position: relative;
      cursor: pointer;
    }
    .circle-back {
      @include flex-centered;
      position: absolute;
      height: 25px;
      width: 25px;
      border-radius: 30px;
      left: -12px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 5px;
      background-color: rgb(255, 255, 255);
      img {
        width: 15px;
      }
    }
    p {
      text-align: center;
      width: 160px;
      margin: 10px 10px;
      .title-label {
        font-weight: 700;
        font-size: 13px;
      }
    }
  }
}