.search-container {
  position: relative;
  .icon-search {
    position: absolute;
    right: 7px;
    top: 19px;
    transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
    // &:hover {
    //   background-color: $light-grey;
    // }
    img {
      width: 18px;
      height: 18px;
    }
  }
  .search {
    width: 280px;
    height: 32px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #ccc;
    color: $primary-color;
    background-color: #fff;
    font-family: roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    border-radius: 35px;
    padding-left: 15px;
  }
  .no-suggestions {
    text-align: center;
  }
  .suggestions {
    position: absolute;
    width: 295px;
    max-height: 300px;
    margin-right: auto;
    text-align: left;
    margin-left: auto;
    border: 1px solid #ccc;
    color: $text-color ;
    background-color: #fff;
    font-family: roboto, Helvetica, Arial, sans-serif;
    font-size: 13px;
    border-radius: 4px;
    padding-inline-start: 0px;
    overflow-y: auto;
    transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: $shadow;
    li {
      padding: 8px 0px 8px 20px;
      list-style: none;
      cursor: pointer;
      transition: all 3s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: $light-grey
      }
    }
  }
}