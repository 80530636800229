.filters-container {
  position: absolute;
  left: 24px;
  top: 15px;
  .filter-resume {
    font-size: 14px;
    color: rgb(181, 181, 181);
  }

  .filters {
    height: 32px;
    display: flex;
    flex-direction: column;
    left: 20px;
    width: auto;
    border: 1px solid transparent;
    border-radius: 16px;
    .filters-btn {
      width: 85px;
      height: 32px;
      display: flex;
      align-items: center;
      font-family: roboto, Helvetica, Arial, sans-serif;
      font-size: 13px;
      font-weight: normal;
      border-radius: 35px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #0091c571 !important;
      }
      .icon-filter {
        width: 16px;
        height: 16px;
        margin-left: 5px;

      }
      span {
        margin-left: 5px;
      }
    }
  }

  .filters-active {
    width: 280px;
    height: auto;
    background-color: $white;
    border: 1px solid #ccc;
    padding-bottom: 10px;
    z-index: 300;
  }

}

.checkbox {
  display: block;
  position: relative;
  padding: 1px 0px 0px 28px;
  margin: 10px;
  color: hsl(0,0%,50%);
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    border-radius: 4px;
    background-color: #eee;
    &.checked {
      background-color: $primary-color;
      &:after {
        content: "";
        position: absolute;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
}