@mixin slideInTop($delay){
  animation-name: slideInTop;
  animation-duration: 0.5s;
  animation-delay: $delay;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
  transform: translate(0px, -100px);
  opacity: 0;
}

@keyframes slideInTop {
  from {
    transform: translate(0px, -100px);
    opacity: 0;
  }

  to {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

@mixin slideOutFade($delay){
  animation-name: slideOutFade;
  animation-duration: 0.5s;
  animation-delay: $delay;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
  transform: translate(0px, -100vh);
  opacity: 0;
}

@keyframes slideOutFade {
  from {
    display: flex;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}

@mixin slideInFade($delay){
  animation-name: slideInFade;
  animation-duration: 0.5s;
  animation-delay: $delay;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes slideInFade {
  from {
    display: none;
    opacity: 0;
  }

  to {
    display: flex;
    opacity: 1;
  }
}

@mixin slideInOpacity($delay){
  animation-name: slideInOpacity;
  animation-duration: 0.5s;
  animation-delay: $delay;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
  opacity: 1;
}

@keyframes slideInOpacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@mixin slideInBottom($delay){
  animation-name: slideInBottom;
  animation-duration: 0.2s;
  animation-delay: $delay;
  transition-timing-function: ease-in;
  animation-fill-mode: forwards;
  transform: translate(0px, 100px);
  opacity: 0;
}

@keyframes slideInBottom {
  from {
    transform: translate(0px, 50px);
    opacity: 0;
  }

  to {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}
