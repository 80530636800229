@mixin label {
  display: block;
  position: absolute;
  font-family: roboto, Helvetica, Arial, sans-serif;
  text-align: center;
  pointer-events: none;
}

.background-viz {
  position: absolute;
  pointer-events: none;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
}

.dataviz-container {
  display: block;
  position: absolute;
  top: 80px;
  left: 0;
  height: 100%;
  width: calc(100% - 350px);
  margin: 0;
  padding: 0;
  .ecosysteme-label {
    @include label();
    text-transform: uppercase;
    width: 150px;
    margin-top: 5px;
  }
  .market-label {
    display: block;
    position: absolute;
    font-family: roboto, Helvetica, Arial, sans-serif;
    /*font-weight: 700;*/
    font-size: 12px;
    max-width: 150px;
    padding: 3px 3px;
    color: #58585a;
    border-radius: 3px;
    font-weight: bold;
    text-shadow: 1px 1px 1px #fff, -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 1px #fff, 0 0 1px #fff;
    pointer-events: none;
    text-align: center;
    margin: 0;
    z-index: 3;
  }

  .eco-button {
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .circle {
    position: absolute ;
    pointer-events: none;
    display: block;
    // margin-left: auto;
    // margin-right: auto;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    pointer-events: all;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.4);
    .circle-hover {
      position: absolute;
      display: none;
      top: 0;
      pointer-events: none;
      justify-content: center;
      align-items: center;
    }
  }
  .eco-label {
    background-color: #fff;
    padding: 2px 8px;
    font-size: 12px;
    border-radius:15px;
    z-index: 600;
    box-shadow: 0px -1px 17px -6px rgba(0,0,0,0.6);
  }
}
