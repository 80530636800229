@import './variables.scss';
@import './sidebar.scss';
@import './search.scss';
@import './dataviz.scss';
@import './filters.scss';
@import './navlinks.scss';
@import 'companyModal.scss';
@import '/animations.scss';


@font-face {
  font-family: 'roboto';
  src: url('../assets/fonts/roboto.woff') format('woff');
  src: url('../assets/fonts/roboto.eot');
  src: url('../assets/fonts/roboto.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto.ttf') format('truetype'), url('../assets/fonts/roboto.svg#roboto') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto-black';
  src: url('../assets/fonts/roboto-black.woff') format('woff');
  src: url('../assets/fonts/roboto-black.eot');
  src: url('../assets/fonts/roboto-black.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/roboto-black.ttf') format('truetype'), url('../assets/fonts/roboto-black.svg#roboto-black') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  font-family: roboto, Helvetica, Arial, sans-serif;

  margin: 0;
  padding: 0;
  overflow: hidden;
  cursor: auto;
}

.logo-audiar {
  position: absolute;
  z-index: 700;
  bottom: 20px;
  left: 20px;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: #58585a;
}

:focus {
  outline: none;
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

h2 {
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
  color: $text-color;
}

h3 {
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

p {
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: $text-color;
}

a {
  font-family: roboto, Helvetica, Arial, sans-serif;
  font-size: 12px;
  color: $primary-color;
}

.error {
  color: $red;
}

.text-primary {
  color: $primary-color;
}

.bold {
  font-weight: 700;
}

.input {
  @include input;
  &.search-user {
    margin-top: 0px;
    height: 32px;
    padding: 0px 0px 0px 15px;
    margin-left: 15px;
  }
}

.header {
  position: absolute;
  width: 100%;
  min-height: 70px;
  z-index: 900;
  display: flex;
  justify-content: center;
}

.loader-container {
  position: absolute;
  width: calc(100% - 350px);
  margin-top: 70px;
  height: calc(100% - 70px);
  background-color: $white;

  z-index:699;
  display: flex;
  justify-content: center;
  align-items: center;
  &.message {
    flex-direction: column;
    span {
      color: $secondary-color;
      font-size: 14px;
    }
  }
}


.btn-primary {
  @include btn;
  justify-content: center;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: rgb(255, 255, 255);
  &.icon {
    img {
      width: 12px;
      margin-right: 10px;
    }
  }
  &.btn-nav {
    margin: 0px 10px 0px 0px;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  &:hover {
    background-color: $primary-hover;
    text-decoration: none;
  }
}

.title-line {
  margin-top: 5px;
  width: 57px;
  border-top: 2px solid $primary-color;
}

.user-name {
  line-height: 1;
  color: $primary-color;
  text-transform: capitalize;
  .last-name {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.password-container {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error {
    height: 30px;
    font-size: 15px
  }
}

// SELECT DROPDOWN INDICATOR
.arrow {
  width: 15px;
  transition: all 0.2s ease-in-out;
}
.arrow-down {
  transform: translateY(2px) rotate(-90deg); 
}
.arrow-up {
  transform: translateY(2px) rotate(90deg); 
}

.explorer {
  @include slideInOpacity(0s);
}
